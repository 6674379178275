import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './OrderConfirmation.module.css';

const OrderConfirmation = ({ setCart }) => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('cart'); 
    setCart([]); 

    setTimeout(() => {
      navigate('/'); 
    }, 5000);
  }, [ setCart, navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Thank You for Your Order!</h1>
        <p className={styles.subtitle}>
          A customer representative will reach out to you within 24-48 hours
        </p>
 <span>Redirecting you to the homepage...</span>
        <div className={styles.loading} aria-live="polite">
          <div className={styles.spinner}></div> 
        </div>

        <div className={styles.manualRedirect}>
          <p>If you are not redirected automatically, click the button below:</p>
          <button className={styles.redirectButton} onClick={() => navigate('/')}>
            Go to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
