import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; 
import styles from './BackBtn.module.css';

const BackBtn = () => {
  const navigate = useNavigate();

  const handleBackClick = useCallback(() => {
    navigate(-1); 
  }, [navigate]);

  return (
    <div>
      <button onClick={handleBackClick} className={styles['back-btn']}>
        Back
      </button>
    </div>
  );
};

export default BackBtn;
