const inventoryData = [
    {
        id: '1',
        img1: "../../Wedding/Menu/60.png",
        img2: "../../Wedding/Menu/59.png",
        alt: "Metallic Menu Card",
        title: "Metallic Menu Card",
        cost: '200',
        include: 'A high-quality menu card with a metallic finish, showcasing your menu items in style',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Flat Menu Card",
        desc: 'Add a touch of glamour to your dining experience with our Metallic Menu Card. Featuring a sleek, reflective finish, this card elevates your menu presentation, making it perfect for upscale events, weddings, or special dinners.',
        url: "/wedding",
        moq: 30
    }, {
        id: '2',
        img1: "../../Wedding/Menu/59.png",
        img2: "../../Wedding/Menu/60.png",
        alt: "Matte Menu Card",
        title: "Matte Menu Card",
        cost: '150',
        include: 'High-quality menu card ready for your custom menu details',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Flat Menu Card",
        desc: 'Designed for simplicity and elegance, this card provides a clean and straightforward way to present your menu items, making it ideal for any event or dining experience.',
        url: "/wedding",
        moq: 30
    }, {
        id: '3',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Arch Menu Card",
        title: "Metallic Arch Menu Card",
        cost: '235',
        include: 'A high-quality menu card with a metallic finish, showcasing your menu items in style.',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Arch Menu Card",
        desc: 'Add a touch of glamour to your dining experience with our Metallic Menu Card. Featuring a sleek, reflective finish, this card elevates your menu presentation, making it perfect for upscale events, weddings, or special dinners.',
        url: "/wedding",
        moq: 30
    }, {
        id: '4',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Arch Menu Card",
        title: "Matte Arch Menu Card",
        cost: '195',
        include: 'A high-quality menu card with a metallic finish, showcasing your menu items in style.',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Arch Menu Card",
        desc: 'Captivate your guests with this beautiful arch matte wedding menu card.',
        url: "/wedding",
        moq: 30
    },
    {
        id: '5',
        img1: "../../Wedding/Invite/70.png",
        img2: "../../Wedding/Invite/70.png",
        alt: "Metallic Invitation Suite",
        title: "Metallic Invitation Suite",
        cost: '945',
        include: 'Invitation, Details and RSVP Card, RSVP Envelope, and Invitation Envelope',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Metallic Invitation",
        desc: 'Invite with style with this elegant set that includes your invitation, details and RSVP card, along with coordinating RSVP and invitation envelopes for a seamless, stylish presentation.',
        url: "/wedding",
        moq: 30
    },
    {
        id: '6',
        img1: "../../Wedding/Invite/80.png",
        img2: "../../Wedding/Invite/80.png",
        alt: "Metallic Flat Invitation",
        title: "Metallic Flat Invitation",
        cost: '365',
        include: 'Invitation & Envelope',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Metallic Invitation",
        desc: 'Celebrate with flair using our stunning new wedding invitation, featuring one elegant invite and a coordinating envelope.',
        url: "/wedding",
        moq: 30
    },
    {
        id: '7',
        img1: "../../Wedding/Shirt/33.png",
        img2: "../../Wedding/Shirt/30.png",  
        alt: "shirt",
        title: "limited Edition Shirt",
        cost: '6999',
        include: 'Two Shirts',
        eventType: "wedding",
        category: "shirt",
        subCategory: "Vinyl",
        desc: 'Long lasting print',
        url: "/wedding",
        moq: 2
    },
    {
        id: '8',
        img1: "../../Wedding/Shirt/12.png",
        img2: "../../Wedding/Shirt/12.png",  
        alt: "shirt",
        title: "Just Married Shirt",
        cost: '6999',
        include: 'Two Custom Shirt',
        eventType: "wedding",
        category: "shirt",
        subCategory: "Vinyl",
        desc: 'Long lasting print',
        url: "/wedding",
        moq: 2
    }, 
    {
        id:'9',
        img1: "../../Wedding/Shirt/13.png",
        img2: "../../Wedding/Shirt/13.png",  
        alt: "shirt",
        title: "Team Bride shirt",
        cost: '6999',
        include: 'Two Custom Shirt',
        eventType: "wedding",
        category: "shirt",
        subCategory: "Vinyl",
        desc: 'Long lasting print',
        url: "/wedding",
        moq: 2
    },
    {
        id: '10',
        img1: "../../Wedding/Invite/22.png",
        img2: "../../Wedding/Invite/21.png",
        alt: "Laser Cut Pocket",
        title: "Laser Cut Pocket",
        cost: '660',
        include: 'Invitation, Detail Card, and Coordinating  Pocket',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Pocket Invitation",
        desc: 'Invite with style using our stunning metallic laser cut pocket invitation, featuring an elegant invite and a detail card with reception and RSVP information, all beautifully held in a sleek, coordinating pocket.',
        url: "/wedding",
        moq: 30
    },
    {
        id: '11',
        img1: "../../Wedding/Invite/23.png",
        img2: "../../Wedding/Invite/24.png",
        alt: "Laser Cut Pocket Fold Invitation",
        title: "Laser Cut Pocket Fold",
        cost: '875',
        include: 'Invitation, Reception & RSVP Details.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Pocket Invitation",
        desc: 'Delight in our luxurious metallic pocket fold laser cut wedding invitation, which includes an elegant invite and a detail card with reception and RSVP information.',
        url: "/wedding",
        moq: 30
    },
    {
        id: '12',
        img1: "../../Wedding/Invite/71.png",
        img2: "../../Wedding/Invite/71.png",
        alt: "Matte Pocket Fold",
        title: "Matte Pocket Fold Invitation",
        cost: '875',
        include: 'Invitation, Reception & RSVP Details.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Pocket Invitation",
        desc: 'Invite with passion using our tri-fold wedding invitation, featuring an elegant invite along with a detail card that includes reception and RSVP information.',
        url: "/wedding",
        moq: 30
    }, 
    {
        id: '13',
        img1: "../../Wedding/Invite/18.png",
        img2: "../../Wedding/Invite/19.png",
        alt: "Pocket Invitation",
        title: "JPG Invite",
        cost: '5000',
        include: 'Digital Wedding Invitation with Reception & RSVP Details',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Digital Wedding Invitation",
        desc: 'Share your special day with our digital invitation, featuring a beautifully designed image that includes all your reception and RSVP details.',
        url: "/wedding",
        moq: 30
    }, 
    {
        id: '61',
        img1: "../../Wedding/Invite/19.png",
        img2: "../../Wedding/Invite/19.png",
        alt: "Animated Digital Invitation",
        title: "Animated MP4 Invite",
        cost: '14999',
        include: 'Invitation, Reception, RSVP & Registry QRCode',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Digital Wedding Invitation",
        desc: 'Share your special day with our high-resolution digital animated invitation, '+
                'showcasing a dynamic design that includes your invite, '+
                'RSVP, reception details, and registry QR codes if needed.',
        url: "/wedding",
        moq: 1
    },  
    {
        id: '62',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Vellum Invitation",
        title: "Vellum Gold Foil Invite",
        cost: '1550',
        include: 'Vellum cover, Gold foil, Gold String Wrap, Wax Seal Emblem, Baby’s Breath Accent, Personalized Invitation Text, Event Details (Date, Time, Venue), RSVP Information, Optional Additional Inserts (Directions, Accommodation)',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Luxury Invitation",
        desc: 'Experience the opulence of our Luxurious Vellum Wrapped Wedding Invitation—a true masterpiece of elegance and refinement. This gold foiled invitation is wrapped with a delicate gold string and sealed with a classic wax emblem, embodying sophistication. Each piece is adorned with a touch of baby’s breath, adding a natural and romantic element.',
        url: "/wedding",
        moq: 15
    }, 
    {
        id: '14',
        img1: "../../Wedding/Invite/65.png",
        img2: "../../Wedding/Invite/1.png",
        alt: "Gold Foil Boarding Pass ",
        title: "Gold Foil Boarding Pass",
        cost: '1000',
        include: 'Boarding pass with invitation, reception and RSVP details.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Ticket Invitation",
        desc: 'Invite with grace with the new gold foil boarding pass wedding invitation. This invite is concealed with a golden string.',
        url: "/wedding",
        moq: 30
    }, 
    {
        id: '15',
        img1: "../../Wedding/Invite/28.png",
        img2: "../../Wedding/Invite/2.png",
        alt: "Matte Boarding Pass Invitation",
        title: "Flat Boarding Pass",
        cost: '620',
        include: 'Boarding pass with invitation, reception and RSVP details.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Ticket Invitation",
        desc: 'Invite with grace with the new matte boarding pass wedding invitation. This invite is consealed with a golden string',
        url: "/wedding",
        moq: 30
    },  
    {
        id: '16',
        img1: "../../Wedding/PlaceCard/77.png",
        img2: "../../Wedding/PlaceCard/77.png", alt: "Place Card",
        title: "Matte Place Card",
        cost:'95',
        include: 'Matte Place Card',
        eventType: "wedding",
        category: "place card",
        subCategory: "Matte Place Card",
        desc: 'Add a touch of sophistication to your wedding reception with our Elegant Matte Place Cards. Crafted from high-quality, durable materials, these cards feature a clean, minimalist design that effortlessly complements any wedding theme. ting with style',
        url: "/wedding",
        moq: 30
    }, {
        id: '17',
        img1: "../../Wedding/PlaceCard/77.png",
        img2: "../../Wedding/PlaceCard/77.png", 
        alt: "Place Card",
        title: "Metallic Place Card",
        cost: '125',
        include: 'A metallic-finished place card, ideal for displaying guest names and table numbers in style.',
        eventType: "wedding",
        category: "place card",
        subCategory: "Matte Place Card",
        desc: 'Add a touch of luxury to your event with our Metallic Place Card. Featuring a shimmering, reflective finish, this card enhances your table settings with elegance and sophistication, perfect for weddings, corporate events, or upscale gatherings.',
        url: "/wedding",
        moq: 30
    }, 
    {
        id: '18',
        img1: "../../Wedding/Box/17.png",
        img2: "../../Wedding/Box/18.png", 
        alt: "Cake box",
        title: "Butterfly Cake Box",
        cost: '4500',
        include: 'Pack of 25 cake boxes with a decorative butterfly design, designed to safely and elegantly hold your cakes.',
        eventType: "wedding",
        category: "box",
        subCategory: "Cake Boxes",
        desc: 'Present your cakes with a touch of whimsy using our Butterfly Cake Box. This L3.5" x W2" x H2.55 butterfly box adds a delightful flair to your cakes, making it perfect for celebrations, gifts, or special occasions. (Pack of 25)',
        url: "/wedding",
        moq: 25
    }, 
    {
        id: '19',
        img1: "../../Wedding/Box/16.png",
        img2: "../../Wedding/Box/16.png", 
        alt: "CupCake box",
        title: "CupCake Box",
        cost: '4750',
        include: 'Pack of 25 Custom CupCake Boxes',
        eventType: "wedding",
        category: "box",
        subCategory: "CupCake Boxes",
        desc: 'Perfect for weddings, bridal showers or any party event. Dimensions: L3.25" x W3.25" x H2.75. (Pack of 25)" ',
        url: "/wedding",
        moq: 25
    }, 
    {
        id: '20',
        img1: "../../Wedding/Box/15.png",
        img2: "../../Wedding/Box/4.png", 
        alt: "Handbag Box",
        title: "Handbag Box",
        cost: '4500',
        include: 'Pack of 25 Custom Favour Boxes. You may request ribbons as an add on at an additional cost.',
        eventType: "wedding",
        category: "box",
        subCategory: "Favor Boxes",
        desc: 'Hand your favor gifts to your guests in style with this luxurious handbag favour box. (Pack of 25)',
        url: "/wedding",
        moq: 25
    }, 
    {
        id: '21',
        img1: "../../Wedding/Box/19.png",
        img2: "../../Wedding/Box/20.png", 
        alt: "Square Ribbon Favor Box",
        title: "Square Ribbon Favor Box",
        cost: '6500',
        include: 'Pack of 25 Custom Favor Boxes designed to your unique wedding theme.',
        eventType: "wedding",
        category: "box",
        subCategory: "Favor Boxes",
        desc: 'Wedding Favor Boxes with Ribbon, - Elegant Gift Boxes for Wedding, Bridal Shower, Birthday, Party Favors. (Pack of 25)',
        url: "/wedding",
        moq: 25
    }, 
    {
        id: '22',
        img1: "../../Wedding/Invite/27.png",
        img2: "../../Wedding/Invite/26.png",
        alt: "Slider Invitation",
        title: "Slider Invitation",
        cost: '945',
        include: 'Invite, Reception Details, RSVP Details & Gift Registry Card',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Slider Invitation",
        desc: 'Create a whimsical and unforgettable invite with our Slider Invitation featuring a moving bride and groom. Pull the slider to reveal a charming animation of the bride meeting the groom, adding a playful and personalized touch to your event. Perfect for weddings and engagement parties..',
        url: "/wedding",
        moq: 30
    }, 
    {
        id: '23',
        img1: "../../Wedding/Invite/46.png",
        img2: "../../Wedding/Invite/46.png",
        alt: "Arch Slider Invitation",
        title: "Arch Slider Invitation",
        cost: '795',
        include: 'An arch-shaped invitation with a sliding panel for custom details and a stylish presentation.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Slider Invitation",
        desc: 'Make a memorable impression with our Arch Slider Invitation. This elegantly designed invitation features a unique sliding mechanism within an arch-shaped frame, offering a sophisticated and interactive touch. Perfect for weddings, anniversaries, or special events.',
        url: "/wedding",
        moq: 30
    }, 
    {
        id: '24',
        img1: "../../Wedding/Souvenir/9.png",
        img2: "../../Wedding/Souvenir/38.png", 
        alt: "Stainless Steel Calendar Keychain",
        title: "Stainless Steel Calendar Keychain",
        cost: '5000',
        include: 'Pack of 2 pieces of stainless steel keychain with an engraved, functional calendar.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Key Chain",
        desc: 'Keep your important dates close at hand with our Stainless Steel Calendar Keychain. This sleek, durable keychain features a finely engraved calendar, allowing you to mark and track your special occasions wherever you go. (Pack of 2)',
        url: "/wedding",
        moq: 2
    },
    {
        id: '25',
        img1: "../../Wedding/Souvenir/52.png",
        img2: "../../Wedding/Souvenir/81.png", 
        alt: "Heart Acrylic Keychain",
        title: "Heart Acrylic Keychain",
        cost: '13000',
        include: 'Pack of 25 heart-shaped acrylic keychain with a clear photo window.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Key Chain",
        desc: 'Celebrate your cherished memories with our Heart Acrylic Photo Keychain. This beautifully crafted keychain features a heart-shaped acrylic charm, offering a clear, stylish display for your favorite photo. (Pack of 25)',
        url: "/wedding",
        moq: 25
},
{
    id: '26',
    img1: "../../Wedding/Souvenir/53.png",
    img2: "../../Wedding/Souvenir/53.png", 
    alt: "Square Acrylic Keychain",
    title: "Square Acrylic Keychain",
    cost: '13000',
    include: 'Pack of 25 square-shaped acrylic keychain with a photo or design of your choice.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Key Chain",
    desc: 'Celebrate your cherished memories with our Square Acrylic Photo Keychain. This beautifully crafted keychain features a square-shaped acrylic charm, offering a clear, stylish display for your favorite photo. (Pack of 25)',
    url: "/wedding",
    moq: 25
},
{
    id: '27',
    img1: "../../Wedding/Invite/4.png",
    img2: "../../Wedding/Invite/6.png",
    alt: "Butterfly Invitation",
    title: "Butterfly Invitation",
    cost: '800',
    include: 'Invitation and envelope',
    eventType: "wedding",
    category: "invitation",
    subCategory: "Gated Invitation",
    desc: 'Crafted with high-quality materials, the metallic finish catches the light, creating a shimmering effect that will leave a lasting impression on your guests. Perfect for weddings, baby showers, or any celebration, this invitation combines artistry and sophistication in one breathtaking package.',
    url: "/wedding",
    moq: 30
},  
{
    id: '28',
    img1: "../../Wedding/Souvenir/51.png",
    img2: "../../Wedding/Souvenir/51.png", 
    alt: "Custom Wine Label",
    title: "Custom Wine Label",
    cost: '700',
    include: 'Pack of 2 pieces of personalized adhesive wine label with your custom design.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Label",
    desc: 'Personalize with your own design or message to create a unique and memorable touch for any occasion. Ideal for gifts, celebrations, or special events, it adds a personal flair to your favorite bottles.',
    url: "/wedding",
    moq: 2
}, {
    id: '29',
    img1: "../../Wedding/Souvenir/10.png",
    img2: "../../Wedding/Souvenir/10.png", 
    alt: "Custom Bottle Label",
    title: "Custom Bottle Label",
    cost: '5000',
    include: 'Pack of 25 pieces of glossy high-quality, crack and peel adhesive bottle label customizable with your design or text',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Label",
    desc: 'Add a personal touch to your bottles with our Custom Bottle Label. Perfect for any occasion, this label lets you showcase your unique design or message, transforming ordinary bottles into memorable keepsakes or gifts.',
    url: "/wedding",
    moq: 25
},{
    id: '30',
    img1: "../../Wedding/Box/8.png",
    img2: "../../Wedding/Box/7.png", 
    alt: "Square Ribbon Pearl Favor box",
    title: "Square Ribbon Pearl Favor Box",
    cost: '5625',
    include: 'Pack of 25 square favor boxed with a decorative ribbon and pearl accent, perfect for holding small gifts or treats.',
    eventType: "wedding",
    category: "box",
    subCategory: "Favor Boxes",
    desc: 'Elegantly present your favors with our Square Ribbon Pearl Favor Box. This charming box features a classic square shape adorned with a delicate ribbon and a lustrous pearl detail, making it a sophisticated choice for weddings, showers, or special events. (Pack of 25)',
    url: "/wedding",
    moq: 25
},  {
    id: '31',
    img1: "../../Wedding/Souvenir/66.png",
    img2: "../../Wedding/Souvenir/79.png", 
    alt: "Bottle Opener Nail Clipper",
    title: "Bottle Opener Nail Clipper",
    cost: '13750',
    include: 'Pack of 25 compact nail clipper with an integrated bottle opener, thank you card and organza bag.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Key Chain",
    desc: 'Get double the utility with our Bottle Opener Nail Clipper. This versatile tool combines a sleek nail clipper with a handy bottle opener, making it a practical accessory for everyday use. Perfect for those who appreciate both functionality and convenience. (Pack of 25)',
    url: "/wedding",
    moq: 25
},
{
    id: '32',
    img1: "../../Wedding/Souvenir/31.png",
    img2: "../../Wedding/Souvenir/32.png", 
    alt: "Elegant Vow Book",
    title: "Vow Book",
    cost: '2800',
    include: 'Pack of 2 vow book contains lined or blank pages, allowing you to write freely and reflect on your promises. With plenty of space for both partners, these books become treasured mementos that you can revisit for years to come. ',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Vow Book",
    desc: 'Designed to hold your personalized vows, these keepsakes provide a special place for you and your partner to express your love and commitment in your own words. (Pack of 2)',
    url: "/wedding",
    moq: 1
},
{
    id: '33',
    img1: "../../Wedding/Invite/82.png",
    img2: "../../Wedding/Invite/82.png",
    alt: "Benelux Program",
    title: "Benelux Fan Program",
    cost: '545',
    include: 'Elegant Benelux Program, Ceremony Details, Guest Information, High-Quality Print',
    eventType: "wedding",
    category: "programme",
    subCategory: "Fan Program",
    desc: 'Elevate your wedding celebration with our Benelux Elegance Wedding Program, designed to reflect the timeless charm and sophistication of the Benelux region. Perfectly crafted to guide your guests through the ceremony, this program combines functionality with a touch of elegance.',
    url: "/wedding",
    moq: 15
},{
    id: '34',
    img1: "../../Wedding/Envelope/2.png",
    img2: "../../Wedding/Envelope/5.png",
    alt: "Gold lined Envelope",
    title: "Gold lined Envelope",
    cost: '2875',
    include: 'Pack of 25 Pieces of Gold Lined Envelopes.',
    eventType: "wedding",
    category: "envelope",
    subCategory: "Envelope",
    desc: 'Matte Gold Lined 5x7 Envelope perfect for weddings birthday party, bridal shower and more. (Pack of 25)',
    url: "/wedding",
    moq: 25
}, 
{
    id: '35',
    img1: "../../Wedding/Souvenir/36.png",
    img2: "../../Wedding/Souvenir/37.png", 
    alt: "Leather Cover Vow Book",
    title: "Leather Cover Vow Book",
    cost: '5785',
    include: 'Pack of 2 Luxurious leather covered vow book contains blank pages. (48 pages) per book.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Vow Book",
    desc: 'Each luxurious leather covered vow book is designed to hold your personalized vows, these keepsakes provide a special place for you and your partner to express your love and commitment in your own words. These 3 7/8" x 5 3/4" wedding books are the perfect size to hold your most important commitments. Our his her vows book is made with high-quality 140GSM thick paper, 24 sheets (48 pages) per book(Pack of 2)',
    url: "/wedding", 
    moq: 1
    },
    {
    id: '36',
    img1: "../../Wedding/Program/39.png",
    img2: "../../Wedding/Program/62.png", 
    alt: "Metallic Rectangular Program",
    title: "Metallic Program",
    cost: '300',
    include: `Made from high-quality cardstock, our programs are both durable and elegant, providing a beautiful keepsake for your guests. The clear and elegant typography ensures easy readability, guiding your loved ones through the ceremony.`,
    eventType: "wedding",
    category: "programme",
    subCategory: "Flat Program",
    desc: 'Elevate your special day with our stunning Metallic Wedding Program, designed to add a touch of elegance and sophistication to your wedding ceremony.',
    url: "/wedding",
    moq: 30
    },
    {
    id: '37',
    img1: "../../Wedding/Box/2.png",
    img2: "../../Wedding/Box/1.png", 
    alt: "25 Gold Foil Favor Box.",
    title: "Gold Foil Favor Box",
    cost: '4625',
    include: 'Pack of 25 Gold Foil Favor Boxes.',
    eventType: "wedding",
    category: "box",
    subCategory: "Cake Boxes",
    desc: `Gold Foil Party Favor Gift Boxes, Candy Boxes for Mother's Day, Easter, Anniversary, Christmas, Graduation, Engagement Celebrations (2.5 x 2.5 x 2.5 Inches). (Pack of 25)`,
    url: "/wedding",
    moq: 25
    },
    {
    id: '38',
    img1: "../../Wedding/Souvenir/15.png",
    img2: "../../Wedding/Souvenir/16.png", 
    alt: "Airplane Bottle Opener Keychains",
    title: "Airplane Bottle Opener Keychains",
    cost: '9975',
    include: 'Pack of 25 Airplane Bottle Opener Keychains, thank you card and organza bag.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Key Chain",
    desc: 'Airplane Bottle Opener Keychains for Wedding or Bridla Shower Favors, Gifts, Souvenirs to Guests (Silver). (Pack of 25)',
    url: "/wedding",
    moq: 25
    },
    {
        id: '39',
        img1: "../../Corporate/Calendar/15.png",
        img2: "../../Corporate/Calendar/15.png",
        alt: "Calendar",
        title: "7 x 11 Calendar",
        cost: '3000',
        include: 'Pack of 10 pieces of 7 x 11 hanging calendar with a streamlined design for easy viewing and scheduling.',
        eventType: "corporate",
        category: "calendar",
        subCategory: "Calendar",
        desc: 'Stay organized and stylish with our 7 x 11 Hanging Calendar. This compact yet versatile calendar features a sleek design that fits perfectly in any space, with ample room for marking important dates and events. Ideal for home or office use. (Pack of 10)',
        url: "/corporate",
        moq: 50
    }, 
    {
        id: '40',
        img1: "../../Corporate/Calendar/16.png",
        img2: "../../Corporate/Calendar/22.png",
        alt: "Calendar",
        title: "11 x 17 Calendar",
        cost: '3000',
        include: '10 pieces of 7 x 11 hanging calendar with a streamlined design for easy viewing and scheduling.',
        eventType: "corporate",
        category: "calendar",
        subCategory: "Calendar",
        desc: 'Stay organized and stylish with our 7 x 11 Hanging Calendar. This compact yet versatile calendar features a sleek design that fits perfectly in any space, with ample room for marking important dates and events. Ideal for home or office use.',
        url: "/corporate",
        moq: 50
    }, 
    {
        id: '41',
        img1: "../../Corporate/Business/11.png",
        img2: "../../Corporate/Business/11.png",
        alt: "One Sided Business Card",
        title: "One Sided Business Card",
        cost: '2000',
        include: 'Pack of 100 high-quality, customizable business cards with your details and design.',
        eventType: "corporate",
        category: "business card",
        subCategory: "Business Card",
        desc: 'Make a strong impression with our Business Card. Designed for professional elegance, this card offers a sleek, customizable layout to showcase your contact information and brand identity. Perfect for networking and leaving a lasting mark. (Pack of 100)',
        url: "/corporate",
        moq: 100
    }, 
    {
        id: '42',
        img1: "../../Corporate/Letterhead/17.png",
        img2: "../../Corporate/Letterhead/17.png",
        alt: "letter head",
        title: "Letter Head 1",
        cost: '2500',
        include: 'A custom-designed letterhead with your branding elements, printed on premium paper for a professional finish.',
        eventType: "corporate",
        category: "letter head",
        subCategory: "Letter Head",
        desc: 'Ideal for businesses, organizations, or personal use, this high-quality stationery features your logo and contact details, providing a polished and cohesive look for all your correspondence.',
        url: "/corporate",
        moq: 1
    }, 
    {
        id: '43',
        img1: "../../Corporate/Letterhead/18.png",
        img2: "../../Corporate/Letterhead/18.png",
        alt: "letter head",
        title: "Letter Head 2",
        cost: '2500',
        include: 'A custom-designed letterhead with your branding elements, printed on premium paper for a professional finish.',
        eventType: "corporate",
        category: "letter head",
        subCategory: "Letter Head",
        desc: 'Ideal for businesses, organizations, or personal use, this high-quality stationery features your logo and contact details, providing a polished and cohesive look for all your correspondence.',
        url: "/corporate",
        moq: 1
    }, 
    {
        id: '44',
        img1: "../../Corporate/Letterhead/19.png",
        img2: "../../Corporate/Letterhead/19.png",
        alt: "letter head",
        title: "Letter Head 3",
        cost: '2500',
        include: 'A custom-designed letterhead with your branding elements, printed on premium paper for a professional finish.',
        eventType: "corporate",
        category: "letter head",
        subCategory: "Letter Head",
        desc: 'Project professionalism with our custom Letterhead. Ideal for businesses, organizations, or personal use, this high-quality stationery features your logo and contact details, providing a polished and cohesive look for all your correspondence.',
        url: "/corporate",
        moq: 1
    }, 
    {id: '45',
        img1: "../../Wedding/Envelope/7.png",
        img2: "../../Wedding/Envelope/8.png",
        alt: "Gold lined Envelope",
        title: "Gold lined Envelope (Long)",
        cost: '3000',
        include: 'Pack of 25 Pieces of 4 1/8 x 9 1/2 Self Seal Standard Gold Lined Envelopes.',
        eventType: "wedding",
        category: "envelope",
        subCategory: "Envelope",
        desc: 'Matte Gold Lined 4 1/8 x 9 1/2 Envelope perfect for weddings birthday party, bridal shower and more. (Pack of 25)',
        url: "/wedding",
        moq: 25
    },
    {
        id: '46',
        img1: "../../Wedding/Souvenir/12.png",
        img2: "../../Wedding/Souvenir/13.png", 
        alt: "25 Clear Shot Glasses",
        title: "Clear Shot Glasses",
        cost: '23625',
        include: 'Pack of 25 High quality small Whiskey shot glass for Vodka, Whiskey, Tequila, Espressos and Liquors.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Label",
        desc: 'Heavy solid glass base, dishwasher safe, tempered glass perfectly balanced to prevent tipping and spilling. These party shot glasses are well-made and fuctional, perfect for consistent use. Perfect for whiskey, tequila, vodka, desserts, party decorations, wedding, birthday and more. (Pack of 25)',
        url: "/wedding",
        moq: 25
    },
    {
        id: '47',
        img1: "../../Wedding/Souvenir/11.png",
        img2: "../../Wedding/Souvenir/5.png", 
        alt: "Heart Bottle Opener Nail Clipper",
        title: "Heart Bottle Opener Nail Clipper",
        cost: '13750',
        include: 'Pack of 25 pieces of heart bottle opener nail clipper, thank you card and organza bag.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Key Chain",
        desc: 'This charming dual-purpose tool combines a stylish bottle opener with a handy nail clipper. Perfect for gifts or personal use, it’s a delightful addition to any collection. (Pack of 25)',
        url: "/wedding",
        moq: 25
    },
    {
        id: '48',
        img1: "../../Wedding/Souvenir/8.png",
        img2: "../../Wedding/Souvenir/3.png", 
        alt: "Custom Round Bottle Opener Nail Clipper",
        title: "Custom Round Bottle Opener Nail Clipper",
        cost: '18750',
        include: 'Pack of 25 Customizable round bottle opener nail clipper.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Key Chain",
        desc: 'Introducing our custom round bottle opener and nail clipper combo! This innovative tool combines a stylish bottle opener with a convenient nail clipper, making it perfect for everyday use or as a unique gift. Personalize it with your engraving for a touch of flair. Practical and fun, it’s an essential addition to your collection! (Pack of 25)',
        url: "/wedding",
        moq: 25
    },
    {
        id: '49',
        img1: "../../Corporate/Shirt/2.png",
        img2: "../../Corporate/Shirt/25.png",
        alt: "custom shirt",
        title: "Shirt",
        cost: '',
        include: 'A customizable t-shirt with your choice of vinyl, heat transfer, or screen print design.',
        eventType: "corporate",
        category: "promotional items",
        subCategory: "Promotional Items",
        desc: 'Create a standout look with our Custom T-Shirt, available in vinyl, heat transfer, or screen print options. Whether you’re designing for a team, event, or personal style, choose the method that best suits your design for vibrant, long-lasting results.',
        url: "/corporate",
        moq: 1
    },
    {
        id: '50',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png", 
        alt: "Satin Ribbon Bow",
        title: "Satin Ribbon Bow - 1 inch",
        cost: '135',
        include: 'High-quality materials, exquisite trimming, and expert bow creation, ensuring a stunning finish for your fan program.',
        eventType: "wedding",
        category: "programme",
        subCategory: "Add on",
        desc: 'Enhance your wedding fan program with our elegant Satin Bow. Crafted from premium materials, each bow features meticulous trimming and expert construction, adding a touch of sophistication to your special day',
        url: "/wedding",
        moq: 15
    },
    {
        id: '51',
        img1: "../../Wedding/Souvenir/1.png",
        img2: "../../Wedding/Souvenir/2.png", 
        alt: "Engraved Metal Bottle Opener",
        title: "Engraved Metal Bottle Opener",
        cost: '12600',
        include: 'Pack of 30 pieces of custom stainless steel bottle oepner with an engraved personalized message and white organza bag.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Key Chain",
        desc: 'Discover our beautifully engraved metal bottle openers, perfect for any occasion. Made from high-quality materials, these personalized openers make great gifts for weddings, anniversaries, or corporate events. Add a unique touch with custom engravings that showcase your style or message. Cheers to memorable moments! (Pack of 30)',
        url: "/wedding",
        moq: 30
    },
    {
        id: '52',
        img1: "../../Corporate/Sticker/1.png",
        img2: "../../Corporate/Sticker/1.png",
        alt: "custom label",
        title: "Custom Bottle Label",
        cost: '1000',
        include: 'Pack of 2 Personalize your products or packages with our Custom Label. Whether for branding, organization, or special events, these labels offer a tailored touch to fit your needs. Choose from a range of sizes and finishes to create a unique, high-quality label.',
        eventType: "corporate",
        category: "sticker",
        subCategory: "Labels",
        desc: 'Customizable label with your design, available in various sizes and finishes for a perfect fit. (Pack of 2)',
        url: "/corporate",
        moq: 2
    },  
    {
        id: '53',
        img1: "../../Corporate/Sticker/13.png",
        img2: "../../Corporate/Sticker/13.png",
        alt: "square stickers",
        title: "50 Square Stickers",
        cost: '1500',
        include: 'Pack of 50 square-shaped stickers with a high-gloss finish for a vibrant, professional appearance.',
        eventType: "corporate",
        category: "sticker",
        subCategory: "Stickers",
        desc: 'Elevate your branding or decoration with our Square Glossy Sticker. Featuring a sleek, shiny finish, these stickers bring a modern touch to your custom designs, making them perfect for logos, labels, or personal touches. (Pack of 50)',
        url: "/corporate",
        moq: 50
    },  
    {
        id: '54',
        img1: "../../Corporate/Sticker/12.png",
        img2: "../../Corporate/Sticker/15.png",
        alt: "Circle Glossy stickers",
        title: "Circle Glossy Stickers",
        cost: '0',
        include: 'Glossy circle stickers in your custom design, delivering a sleek and eye-catching look.',
        eventType: "corporate",
        category: "sticker",
        subCategory: "Stickers",
        desc: 'Add a pop of shine to any surface with our Circle Glossy Stickers. These vibrant, high-quality stickers feature a glossy finish that makes your designs stand out. Perfect for branding, decoration, or personalizing items.',
        url: "/corporate",
        moq: 50
    },  
    {
        id: '55',
        img1: "../../Wedding/Envelope/11.png",
        img2: "../../Wedding/Envelope/10.png",
        alt: "Metallic Envelope",
        title: "Metallic Envelope",
        cost: '2750',
        include: 'Pack of 25 Pieces of Metallic Envelope',
        eventType: "wedding",
        category: "envelope",
        subCategory: "Envelope",
        desc: 'Metallic 5x7 Envelope perfect for weddings, birthday party, bridalshower and more. (Pack of 25)',
        url: "/wedding",
        moq: 25
    },  
    {
        id: '56',
        img1: "../../Corporate/Banner/5.png",
        img2: "../../Corporate/Banner/5.png",
        alt: "Banner",
        title: "Vinyl Banner - 4ft x 5ft",
        cost: '15200',
        include: 'Vinyl Banner',
        eventType: "corporate",
        category: "banner",
        subCategory: "Banner",
        desc: 'Perfect for displaying your upcoming event.',
        url: "/corporate",
        moq: 1
    }, 
    {
        id: '57',
        img1: "../../Corporate/Banner/36.png",
        img2: "../../Corporate/Banner/36.png",
        alt: "Banner",
        title: "Retractable Banner",
        cost: '25200',
        include: 'Retractable Banner - 4ft x 5ft',
        eventType: "corporate",
        category: "banner",
        subCategory: "Banner",
        desc: 'Perfect for displaying your upcoming event.',
        url: "/corporate",
        moq: 1
    }, 
   
    {
        id: '58',
        img1: "../../Wedding/Souvenir/35.png",
        img2: "../../Wedding/Souvenir/34.png",
        alt: "souvenir & favours for dad",
        title: "Custom mugs for dad",
        cost: '6500',
        include: 'Pack of 2 high-quality mug customized with your choice of text or design, tailored to make Dad’s day extra special.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Custom Mugs",
        desc: 'Celebrate Dad with our Custom Mugs, designed to make his mornings special. Personalize with a unique message, photo, or design to create a thoughtful and memorable gift. Perfect for Father’s Day, birthdays, or any occasion that honors the special man in your life. (Pack of 2)',
        url: "/wedding",
        moq: 1
    }, 
    {
        id: '59',
        img1: "../../Wedding/Souvenir/6.png",
        img2: "../../Wedding/Souvenir/34.png",
        alt: "souvenir & favours",
        title: "Jamaican mugs",
        cost: '6500',
        include: 'Pack of 2 high-quality mug featuring your custom Jamaican-themed design, crafted for durability and style.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Custom Mugs",
        desc: 'Show off your Jamaican pride with our Custom Jamaican Mugs. Personalize with vibrant designs, text, or photos that celebrate Jamaica rich culture and beauty. Perfect for gifting or adding a splash of island spirit to your daily routine. (Pack of 2)',
        url: "/wedding",
        moq: 1
    },  {
        id: '60',
        img1: "../../Wedding/Souvenir/7.png",
        img2: "../../Wedding/Souvenir/34.png",
        alt: "souvenir & favours",
        title: "Custom magic mugs",
        cost: '7000',
        include: 'Pack of 2 high-quality magic mug customized with your choice of text or design.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Custom Mugs",
        desc: 'Personalize with a heartfelt message, photo, or design to create a special gift she will cherish with every sip. Perfect for Mothers Day, birthdays, or just because. (Pack of 2)',
        url: "/wedding",
        moq: 1
    }, 
    {
        id: '61',
        img1: "../../Wedding/Envelope/4.png",
        img2: "../../Wedding/Envelope/3.png",
        alt: "Wax Seal",
        title: "Wax Seal",
        cost: '2879',
        include: '25 pieces  of Wax Seal',
        eventType: "wedding",
        category: "envelope",
        subCategory: "Envelope",
        desc: 'Adhesive Wax seal, perfect for adding a touch of elegance to your invitation. Please contact us for customized options.',
        url: "/wedding",
        moq: 25
    }, 
    {
        id: '63',
        img1: "../../Corporate/Tag/28.png",
        img2: "../../Corporate/Tag/29.png",
        alt: "custom Tag",
        title: "Tag",
        cost: '1000',
        include: 'Pack of 100 customizable clothing tag with your design, logo, and details, crafted from durable materials.',
        eventType: "corporate",
        category: "promotional items",
        subCategory: "Promotional Items",
        desc: 'Enhance your brand identity with our Custom Clothing Tag. Perfect for adding a personalized touch to your apparel, this tag features high-quality materials and customizable designs to reflect your unique style. Ideal for clothing lines, boutiques, or special collections. (Pack of 100)',
        url: "/corporate",
        moq: 100
    }, 
    {
        id: '64',
        img1: "../../Wedding/Invite/67.png",
        img2: "../../Wedding/Invite/67.png",
        alt: "Vellum Invitation",
        title: "Vellum Invitation",
        cost: '820',
        include: 'Vellum cover, Gold String Wrap, Wax Seal Emblem, Baby’s Breath Accent, Personalized Invitation Text, Event Details (Date, Time, Venue), RSVP Information, Optional Additional Inserts (Directions, Accommodation)',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Luxury Invitation",
        desc: 'Experience the opulence of our Luxurious Vellum Wrapped Wedding Invitation—a true masterpiece of elegance and refinement. This invitation is wrapped with a delicate gold string and sealed with a classic wax emblem, embodying sophistication. Each piece is adorned with a touch of baby’s breath, adding a natural and romantic element.',
        url: "/wedding",
        moq: 15
    }, 
    {
        id: '65',
        img1: "../../Wedding/Souvenir/4.png",
        img2: "../../Wedding/Souvenir/4.png", 
        alt: "Bottle Opener Keychain",
        title: "Bottle Opener Keychain",
        cost: '6500',
        include: 'Pack of 10 pieces of customized acrylic keychain with an integrated bottle opener.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Key Chain",
        desc: 'Combine convenience with style using our Bottle Opener Acrylic Keychain. This sleek, durable keychain features a built-in bottle opener, perfect for on-the-go refreshment. (Pack of 10)',
        url: "/wedding",
        moq: 10
    },
    {
        id: '66',
        img1: "../../Wedding/Shirt/27.png",
        img2: "../../Wedding/Shirt/31.png",  
        alt: "shirt",
        title: "Jamaican Shirt",
        cost: '6999',
        include: 'Two Shirts',
        eventType: "wedding",
        category: "shirt",
        subCategory: "Vinyl",
        desc: 'Long lasting print',
        url: "/wedding",
        moq: 1
    }, 
    {
        id: '67',
        img1: "../../Corporate/ThankYouCard/4.png",
        img2: "../../Corporate/ThankYouCard/4.png",  
        alt: "shirt",
        title: "Thank You Card",
        cost: '3500',
        include: '50 Thank You Card',
        eventType: "corporate",
        category: "promotional cards",
        subCategory: "Promotional Cards",
        desc: 'Long lasting print',
        url: "/corporate",
        moq: 50
    }, 
    {
        id: '68',
        img1: "../../OtherServices/TShirtBrand/8.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Limited Edition",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
        moq: 1
    }, 
    {
        id: '69',
        img1: "../../OtherServices/TShirtBrand/21.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "100 Percent Jamaican",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
        moq: 1
    }, 
    {
        id: '70',
        img1: "../../OtherServices/TShirtBrand/5.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Be Yourself",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
        moq: 1
    }, 
    {
        id: '71',
        img1: "../../OtherServices/TShirtBrand/22.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Be Yourself",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
        moq: 1
    }, 
    {
        id: '72',
        img1: "../../OtherServices/TShirtBrand/7.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Live Love Inspire",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
        moq: 1
    },   {
        id: '73',
        img1: "../../OtherServices/TShirtBrand/9.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Jamaican Time",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
        moq: 1
    }, 
    {
        id: '74',
        img1: "../../OtherServices/GraphicDesignClass/9.png",
        img2: "../../OtherServices/GraphicDesignClass/9.png",  
        alt: "training",
        title: "Adobe Illustrator",
        cost: '0',
        include: '4 Weeks (3 hours per session)',
        eventType: "otherServices",
        category: "training",
        subCategory: "Graphic Design",
        desc: 'This plan covers the fundamentals of Adobe Illustrator, design principles, advanced features, and practical applications.',
        url: "/other-services",
        moq: 0
    }, 
    {
        id: '76',
        img1: "../../OtherServices/GraphicDesignClass/8.png",
        img2: "../../OtherServices/GraphicDesignClass/8.png",  
        alt: "training",
        title: "Canva",
        cost: '0',
        include: '6 Weeks (3 hours per session)',
        eventType: "otherServices",
        category: "training",
        subCategory: "Graphic Design",
        desc: 'This plan covers the fundamentals of Canva, design principles, advanced features, and practical applications.',
        url: "/other-services",
        moq: 0
    }, 
    {
        id: '75',
        img1: "../../OtherServices/GraphicDesignClass/7.png",
        img2: "../../OtherServices/GraphicDesignClass/7.png",  
        alt: "training",
        title: "Adobe Photoshop",
        cost: '0',
        include: '6 Weeks (3 hours per session)',
        eventType: "otherServices",
        category: "training",
        subCategory: "Graphic Design",
        desc: 'This plan covers the fundamentals of Photoshop, design principles, advanced features, and practical applications.',
        url: "/other-services",
        moq: 0
    }, 
  
    {
        id: '77',
        img1: "../../Corporate/Menu/20.png",  
        img2: "../../Corporate/Menu/20.png",  
        alt: "11x17 Menu Card",
        title: "11x17 Menu Card",
        cost: '500',
        include: "Durable 11x17 inches laminated finish, Customizable Design, Content of your choice.",
        eventType: "corporate",
        category: "menu",
        subCategory: "Menu Styles",
        desc: "Transform your restaurant’s dining experience with our elegantly designed 11x17 laminated menu card. This high-quality menu card features a sleek single-page layout, perfect for showcasing a variety of dishes and beverages. The durable lamination ensures longevity and resistance to spills and stains, while the customizable design allows for a polished and professional presentation. Ideal for enhancing your restaurant’s ambiance and providing clear, stylish information to your customers.",
        url: "/corporate",
        moq: 10
    }, 
    {
        id: '78',
        img1: "../../Corporate/Menu/20.png",  
        img2: "../../Corporate/Menu/20.png",  
        alt: "Menu Booklet",
        title: "8.5x11 Menu Booklet",
        cost: '4499',
        include: "Custom 8.5x11 design, Durable laminated finish, Multi-page layout, High-resolution printing, Customizable sections, Detailed dish descriptions, Contact information.",
        eventType: "corporate",
        category: "menu",
        subCategory: "Menu Styles",
        desc: "Transform your restaurant’s dining experience with our elegantly designed 8.5x11 menu card booklet. This high-quality menu card features a sleek single-page layout, perfect for showcasing a variety of dishes and beverages. The durable lamination ensures longevity and resistance to spills and stains, while the customizable design allows for a polished and professional presentation. Ideal for enhancing your restaurant’s ambiance and providing clear, stylish information to your customers.",
        url: "/corporate",
        moq: 3
    }, 
    {
        id: '79',
        img1: "../../Other/logo.png",  
        img2: "../../Other/logo.png",  
        img3: "../../Corporate/Menu/20.png",  
        alt: "Simple",
        title: "Simple Logo",
        cost: '5499',
        include: "One text-based logo design, High-resolution logo files in PNG and JPEG formats, Logo provided in three formats: round background, square background, and transparent background, One round of revision.",
        eventType: "corporate",
        category: "logo",
        subCategory: "Logo Packages",
        desc: "Get your brand off the ground with our Simple Logo Package. This package offers a high-quality, text-based logo designed to establish your brand identity with clarity and style. You will receive your logo in three versatile formats",
        url: "/corporate",
        moq: 0
    }, 
    {
        id: '80',
        img1: "../../Other/logo.png",  
        img2: "../../Other/logo.png",  
        alt: "Complex Logo",
        title: "Complex Logo",
        cost: '7499',
        include: "One custom logo design incorporating text and imagery, High-resolution logo files in PNG, JPEG, Logo provided in multiple formats: round background, square background, and transparent background, Two rounds of revisions.",
        eventType: "corporate",
        category: "logo",
        subCategory: "Logo Packages",
        desc: "Elevate your brand with our Complex Logo Package. This comprehensive package delivers a custom-designed logo that integrates both text and imagery, creating a distinctive and impactful brand identity. With two rounds of revisions, you'll have the opportunity to refine the design to perfection. You'll also receive your logo in multiple high-resolution formats and vector files for ultimate versatility.",
        url: "/corporate",
        moq: 0
    }, 
    {
        id: '81',
        img1: "../../Corporate/Business/11.png",
        img2: "../../Corporate/Business/11.png",
        alt: "business card",
        title: "Two Sided Business Card",
        cost: '4000',
        include: '100 high-quality, customizable business cards with your details and design.',
        eventType: "corporate",
        category: "business card",
        subCategory: "Business Card",
        desc: 'Make a strong impression with our Business Card. Designed for professional elegance, this card offers a sleek, customizable layout to showcase your contact information and brand identity. Perfect for networking and leaving a lasting mark.',
        url: "/corporate",
        moq: 100
    }, 
    {
        id: '82',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",  
        alt: "Loyalty Card",
        title: "100 Loyalty Cards",
        cost: '2000',
        include: '100 high-quality Loyalty Card, customizable cards with your details and design',
        eventType: "corporate",
        category: "promotional cards",
        subCategory: "Promotional Cards",
        desc: 'High-quality Loyalty Card perfect for customer retention.',
        url: "/corporate",
        moq: 100
    }, 
    {
        id: '83',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",  
        alt: "Discount Card",
        title: "Discount Card",
        cost: '2000',
        include: '100 high-quality Discount Card, customizable cards with your details and design',
        eventType: "corporate",
        category: "promotional cards",
        subCategory: "Promotional Cards",
        desc: 'High-quality Discount Card perfect for customer retention.',
        url: "/corporate",
        moq: 100
    }, 
    {
        id: '84',
        img1: "../../Wedding/Program/61.png",
        img2: "../../Wedding/Program/61.png", 
        alt: "Matte Rectangular Program",
        title: "Matte Program",
        cost: '200',
        include: ' The Matte Wedding Program includes a clean and minimalist design that features high-quality cardstock, making it both durable and sophisticated. Each program can be personalized with your names, wedding date, and a brief message, allowing you to add a personal touch without overwhelming the design.',
        eventType: "wedding",
        category: "programme",
        subCategory: "Flat Program",
        desc: 'Our Matte Wedding Program offers a classic and elegant option for couples looking to keep things simple while still providing essential information for their guests. This program is designed to seamlessly integrate into any wedding theme, ensuring that your special day remains the focus.',
        url: "/wedding",
        moq: 30
    },
    {
        id: '85',
        img1: "../../Wedding/Program/36.png",
        img2: "../../Wedding/Program/37.png", 
        alt: "Fan Program",
        title: "Fan Program",
        cost: '515',
        include: 'Matte Rectangular Program',
        eventType: "wedding",
        category: "programme",
        subCategory: "Fan Program",
        desc: 'Never get hot during your special event.',
        url: "/wedding",
        moq: 15
    },
    {
        id: '86',
        img1: "../../Wedding/PlaceCard/TableNumber.png",
        img2: "../../Wedding/PlaceCard/TableNumber2.png", 
        alt: "Table Number",
        title: "Table Number",
        cost: '250',
        include: 'Table Number Card',
        eventType: "wedding",
        category: "table card",
        subCategory: "4x6 Table Number",
        desc: 'Sleek elegant table number crafted from C1s Cardstock. Dimensions: W4" x L6" ',
        url: "/wedding",
        moq: 30
    },
    {
        id: '87',
        img1: "../../Wedding/Invite/81.png",
        img2: "../../Wedding/Invite/81.png",
        alt: "Velvet Invitation",
        title: "Velvet Invitation",
        cost: '1579',
        include: 'Gold Foil Detailing, Velvet Cover, Gold String Wrap, Wax Seal Emblem, Baby’s Breath Accent, Personalized Invitation Text, Event Details (Date, Time, Venue), RSVP Information, Optional Additional Inserts (Directions, Accommodation)',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Luxury Invitation",
        desc: 'Experience the opulence of our Luxurious Gold Foil Wedding Invitation—a true masterpiece of elegance and refinement. Encased in a sumptuous velvet cover, this invitation is wrapped in a delicate gold string and sealed with a classic wax emblem, embodying sophistication. Each piece is adorned with a touch of baby’s breath, adding a natural, romantic element that perfectly complements the gold foil detailing.',
        url: "/wedding",
        moq: 15
    }, 
    {
        id: '88',
        img1: "../../Wedding/Invite/74.png",
        img2: "../../Wedding/Invite/74.png",
        alt: "Matte Invitation Suite",
        title: "Matte Invitation Suite",
        cost: '850',
        include: 'Invitation, Details and RSVP Card, RSVP Envelope, and Invitation Envelope',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Matte Invitation",
        desc: 'Invite with style with this elegant set that includes your invitation, details and RSVP card, along with coordinating RSVP and invitation envelopes for a seamless, stylish presentation.',
        url: "/wedding",
        moq: 30
    },
    {
        id: '89',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Acrylic Invite Only",
        title: "Acrylic Invite",
        cost: '0',
        include: 'Invitation and Envelope',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Acrylic Invitation",
        desc: 'Invite with style with this elegant set that includes your invitation, details and RSVP card, along with coordinating RSVP and invitation envelopes for a seamless, stylish presentation.',
        url: "/wedding",
        moq: 15
    },
    {
        id: '90',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Acrylic Invite Suite",
        title: "Acrylic Invite Suite",
        cost: '0',
        include: 'Invitation, RSVP, Invitation Envelope and RSVP Envelope',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Acrylic Invitation",
        desc: 'Invite with style with this elegant set that includes your invitation, details and RSVP card, along with coordinating RSVP and invitation envelopes for a seamless, stylish presentation.',
        url: "/wedding",
        moq: 15
    },
    {
        id: '91',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Arch Acrylic Table Number",
        title: "Arch Acrylic Table Number",
        cost: '0',
        include: '10 Arch Acrylic Table Numbers',
        eventType: "wedding",
        category: "table card",
        subCategory: "4x6 Table Number",
        desc: 'Sleek elegant Arch Acrylic table number. Dimensions: W4" x L6" ',
        url: "/wedding",
        moq: 10
    },
    {
        id: '92',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Rectangular Acrylic Table Number",
        title: "Rectangular Acrylic Table Number",
        cost: '0',
        include: '10 Rectangular Acrylic Table Numbers',
        eventType: "wedding",
        category: "table card",
        subCategory: "4x6 Table Number",
        desc: 'Sleek elegant Rectangular Acrylic table number. Dimensions: W4" x L6" ',
        url: "/wedding",
        moq: 10
    },
    {
        id: '93',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Bi-fold Metallic Menu Card",
        title: "Bi-fold Metallic Menu Card",
        cost: '0',
        include: 'A high-quality menu card with a metallic finish, showcasing your menu items in style',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Bi-Fold Menu Card",
        desc: 'Add a touch of glamour to your dining experience with our Metallic Menu Card. Featuring a sleek, reflective finish, this card elevates your menu presentation, making it perfect for upscale events, weddings, or special dinners.',
        url: "/wedding",
        moq: 30
    }, 
    {
        id: '94',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Bi-fold Matte Menu Card",
        title: "Bi-fold Matte Menu Card",
        cost: '0',
        include: 'A high-quality menu card with a matte finish, showcasing your menu items in style',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Bi-Fold Menu Card",
        desc: 'Add a touch of glamour to your dining experience with our Matte Menu Card. Featuring a sleek, reflective finish, this card elevates your menu presentation, making it perfect for upscale events, weddings, or special dinners.',
        url: "/wedding",
        moq: 30
    }, 
];

export default inventoryData;