import { React, useEffect, useState, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './CartPage.module.css'; 
import CheckoutModal from '../../components/CheckoutModal'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const CartPage = ({ cart, setCart, removeFromCart }) => {
    const navigate = useNavigate();
    const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);

    // Memoize cart updates to prevent unnecessary writes to localStorage
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]); 

    const handleCheckout = () => {
        setIsCheckoutModalOpen(true); 
    };

    const handleIncreaseQuantity = useCallback((itemId) => {
        const updatedCart = cart.map(item =>
            item.id === itemId ? { ...item, quantity: item.quantity + 1 } : item
        );
        setCart(updatedCart); 
    }, [cart, setCart]);

    const handleDecreaseQuantity = useCallback((itemId) => {
        const updatedCart = cart.map(item =>
            item.id === itemId && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
        );
        setCart(updatedCart);
    }, [cart, setCart]);

    const handleRemoveItem = useCallback((itemId) => {
        removeFromCart(itemId);
    }, [removeFromCart]);

    const totalPrice = useMemo(() => {
        return cart.reduce((total, item) => total + item.price * item.quantity, 0);
    }, [cart]);

    const truncateDescription = (desc) => {
        return desc.length > 100 ? `${desc.slice(0, 100)}...` : desc;
    };

    const formatCost = (cost) => {
        const options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        return new Intl.NumberFormat('en-US', options).format(cost);
    };

    return (
        <div>
            {cart.length === 0 ? (
                <div className={styles.emptyCartContainer}>
                    <FontAwesomeIcon icon={faShoppingCart} className={styles.emptyCartIcon} />
                    <h2>Your cart is empty</h2>
                    <p>It looks like you haven't added anything to your cart yet. Start shopping now!</p>
                    <button className={styles.startShoppingBtn} onClick={() => navigate('/')}>
                        Start Shopping
                    </button>
                </div>
            ) : (
                <div className={styles.cartContainer}>
                    <div className={styles.cartItems}>
                        <h1>Shopping Cart</h1>
                        {cart.map(item => (
                            <div key={item.id} className={styles.cartItem}>
                                <div className={styles.cartItemImage}>
                                    <Link to={`/${item.event}/${item.id}/${item.title}`} className={styles.productLink}>
                                        <img src={item.img} alt={item.title} />
                                    </Link>
                                </div>
                                
                                <div className={styles.cartItemDetails}>
                                    <Link to={`/${item.event}/${item.id}/${item.title}`}>
                                        <p className={styles.cartItemTitle}>{item.title}</p>
                                        <p className={styles.cartItemDescription}>{truncateDescription(item.desc)}</p>
                                    </Link>
                                </div>

                                <div className={styles.cartItemActions}>
                                    <div className={styles.cartItemPrice}>
                                        <p>${formatCost((item.price * item.quantity).toFixed(2))}</p>
                                    </div>
                                    <div className={styles.cartItemQuantity}>
                                        <button 
                                            onClick={() => handleDecreaseQuantity(item.id)} 
                                            aria-label={`Decrease quantity of ${item.title}`} 
                                            disabled={item.quantity <= 1}
                                        >
                                            -
                                        </button>
                                        <span>{item.quantity}</span>
                                        <button 
                                            onClick={() => handleIncreaseQuantity(item.id)} 
                                            aria-label={`Increase quantity of ${item.title}`}
                                        >
                                            +
                                        </button>
                                    </div>
                                    <div 
                                        className={styles.removeButton} 
                                        onClick={() => handleRemoveItem(item.id)} 
                                        aria-label={`Remove ${item.title} from cart`}
                                    >
                                        <FontAwesomeIcon icon={faTrash} className={styles.trashIcon} />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className={styles.cartSummary}>
                            <p><strong>Subtotal:</strong> ${formatCost(totalPrice.toFixed(2))}</p>
                        </div>
                       
                        <button className={styles.checkoutButton} onClick={handleCheckout}>Proceed to Checkout</button>
                        {isCheckoutModalOpen && <CheckoutModal isOpen={isCheckoutModalOpen} onClose={() => setIsCheckoutModalOpen(false)} />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CartPage;
