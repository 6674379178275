import React from 'react';
// import heroBg from '../../assets/logo.png';
import styles from './RangeOfProducts.module.css';
const Mission = () => {
    return (
        <div className={styles.heroContainer}>
            <div className={styles.textContainer}>
            <h1 className={styles.headerText}>Our Range of Products</h1>
            <p className={styles.subText}>Discover a wide array of creative stationery products to enhance your special moments:</p>

                <ul>
                    <li className={styles.subText}>
                        <span><b>Invitations: </b></span>
                        <span  className={styles.subTextSpan}>Set the event tone with stunning, custom invitations.</span>
                    </li>
                    <li className={styles.subText}>
                        <span><b>Flyers: </b></span>
                        <span className={styles.subTextSpan}>Make a statement with impactful, eye-catching flyers.</span>
                    </li>
                    <li className={styles.subText}>
                        <span><b>Business Cards: </b></span>
                        <span className={styles.subTextSpan}> Leave an impression with professional business cards.</span>
                    </li>
                    <li className={styles.subText}>
                        <span><b>Menu Cards: </b></span>
                        <span className={styles.subTextSpan}> Enhance dining with beautifully crafted menu cards.</span>
                    </li>
                    <li className={styles.subText}>
                        <span><b>Cake Toppers: </b> </span>
                        <span className={styles.subTextSpan}> Personalize your cakes with our special toppers.</span>
                    </li>
                    <li className={styles.subText}>
                        <span><b>Programs: </b> </span>
                        <span className={styles.subTextSpan}> Keep your guests informed with elegant event programs.</span>
                    </li>
                    <li className={styles.subText}>
                        <span><b>Stickers: </b>  </span>
                        <span className={styles.subTextSpan}>Make your products or gifts stand out with custom stickers.</span>
                    </li>
                    <li className={styles.subText}>
                        <span><b>T-Shirts: </b>  </span>
                        <span className={styles.subTextSpan}>Wear your creativity with pride with our custom-designed t-shirts.</span>
                    </li>
                    <li className={styles.subText}>
                        <span><b>And More: </b>   </span>
                        <span  className={styles.subTextSpan}>Explore our extensive catalog for additional creative stationery options.</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Mission;