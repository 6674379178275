import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import Nav from './components/kevnavbar';
import Landing from './pages/landing/Landing';
import Wedding from './pages/wedding/Wedding';
import OtherServices from './pages/otherServices/OtherServices';
import OtherServicesCatalogue from './pages/otherServices/OtherServicesCatalogue';
import Footer from './components/Footer';
import './index.css';
import Catalogue from './pages/wedding/Catalogue';
import Corporate from './pages/corporate/Corporate';
import CorporateCatalogue from './pages/corporate/CorporateCatalogue';
import AboutUs from './pages/about-us/AboutUs';
import Reviews from './pages/reviews/Reviews';
import TermsAndConditions from './pages/policies/TermsAndConditions';
import FAQ from './pages/faq/FAQ';
import ShippingPolicy from './pages/policies/ShippingPolicy';
import RefundPolicy from './pages/policies/RefundPolicy';
import Banner from './components/Banner/Banner';
import FormList from './pages/formList/FormList';
import ScrollToTop from './components/Scroll/ScrollToTop';
import Blog from './pages/blog/Blog';
import OrderConfirmation from './pages/OrderConfirmation/OrderConfirmation';
import Product from './components/Product';
import CartPage from './pages/cart/cartPage';

function App() {
    const [cart, setCart] = useState(() => {
        const storedCart = localStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : []; 
    });

    const addToCart = (newItem) => {
        const updatedCart = [...cart];
        const existingItemIndex = updatedCart.findIndex(item => item.id === newItem.id);

        if (existingItemIndex !== -1) {
            updatedCart[existingItemIndex].quantity += newItem.quantity;
        } else {
            updatedCart.push(newItem);
        }

        setCart(updatedCart); 
        localStorage.setItem('cart', JSON.stringify(updatedCart)); 
    };

    const removeFromCart = (itemId) => {
        const updatedCart = cart.filter(item => item.id !== itemId);
        setCart(updatedCart); 
        localStorage.setItem('cart', JSON.stringify(updatedCart)); 
    };

    const getCartItemCount = () => {
        return cart.length;
    };

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Nav cartItemCount={getCartItemCount()} /> 
            <Banner />
            <Routes>
                <Route path='/order-confirmation' element={<OrderConfirmation setCart={setCart} />} />
                <Route path="/cart" element={<CartPage cart={cart} setCart={setCart} removeFromCart={removeFromCart} />} />
                <Route path='/' element={<Landing />} />
                <Route path='/about-us' element={<AboutUs />} />
                <Route path='/faqs' element={<FAQ />} />
                <Route path='/neikas-wedding-corner' element={<Blog />} />
                <Route path='/forms' element={<FormList />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/shipping-policy' element={<ShippingPolicy />} />
                <Route path='/refund-policy' element={<RefundPolicy />} />
                <Route path='/reviews' element={<Reviews />} />
                <Route path='/corporate' element={<Corporate />} />
                <Route path='/corporate/:productCategory' element={<CorporateCatalogue />} />
                <Route path='/corporate/:productId/:productTitle' element={<Product addToCart={addToCart} />} />
                <Route path='/wedding' element={<Wedding />} />
                <Route path='/wedding/:productCategory' element={<Catalogue />} />
                <Route path='/wedding/:productId/:productTitle' element={<Product addToCart={addToCart} />} />
                <Route path='/other-services' element={<OtherServices />} />
                <Route path='/other-services/:productCategory' element={<OtherServicesCatalogue />} />
                <Route path='/other-services/:productId/:productTitle' element={<Product addToCart={addToCart} />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
