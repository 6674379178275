import React from 'react';
import Slider from 'react-slick';
import styles from './HeroHeader.module.css';
// import WhatsappBtn from '../WhatsappBtn';
import content2 from '../../assets/images/promo/promo2.png';  

const HeroHeader = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,  
        autoplaySpeed: 3000  
    };

    return (
        <div className={styles.heroContainer}>
            <div className={styles.sliderContainer}>
                <Slider {...settings}>
                    <div>
                        <img src={content2} alt="Hero 1" className={styles.heroImage} />
                    </div>
                  
                </Slider>
            </div>
            {/* Optional: Uncomment if you need the text container */}
            {/* <div className={styles.textContainer}>
                <h1 className={styles.headerText}>Personalized Cards & Invitations</h1>
                <p className={styles.subText}>"Handing value to your loved ones."</p>
                <WhatsappBtn title='Whatsapp Us'/>
            </div> */}
        </div>
    );
};

export default HeroHeader;
