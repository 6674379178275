import ProductCategoryCard from './ProductCategoryCard';
import styles from './ProductCarousel.module.css';

const ProductCategoryCarousel = ({ data }) => {

    return (
        <div className={styles['product-carousel-container']}>
            <div className={styles['product-list-container']}>
                {data.map((product, index) => (
                    <div key={index} className={styles['product-card-container']}>
                        <ProductCategoryCard product={product} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductCategoryCarousel;
