import React, { useState } from 'react';
import HeaderSection from '../../components/HeaderSection';
import styles from './Blog.module.css';
import { Helmet } from 'react-helmet';
import blogs from '../../data/blogData';

const Blog = () => {
    const [selectedBlog, setSelectedBlog] = useState(blogs[0]);

    const handleCardClick = (id) => {
        const blog = blogs.find(b => b.id === id);
        if (blog) {
            setSelectedBlog(blog);
            window.scrollTo(0, 0);
        }
    };

    const truncateContent = (content) => {
        const text = content.replace(/<\/?[^>]+(>|$)/g, " ");
        return text.length > 100 ? text.substring(0, 100) + '...' : text;
    };

    return (
        <div>
            <Helmet>
                <title>Personalized Cards and Invitations - Blog</title>
                <meta name="description" content="Discover expert tips and advice for brides and grooms. Our blog offers guidance on wedding planning, stationery choices, and everything you need for your special day." />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap" />
            </Helmet>
            <HeaderSection title="Neika's Wedding Corner" />
            <div className={styles.container}>
                <div className={styles.blogContent}>
                    <div className={styles.mainBlog}>
                        <img src={selectedBlog.image} alt="Blog" className={styles.blogImage} />
                        <h2 className={styles.blogHeader}>{selectedBlog.header}</h2>
                        <div className={styles.blogText} dangerouslySetInnerHTML={{ __html: selectedBlog.content }} />
                    </div>
                </div>
                <div className={styles.blogCards}>
                    {blogs.map(blog => (
                        <div
                            key={blog.id}
                            className={styles.blogCard}
                            onClick={() => handleCardClick(blog.id)}
                        >
                            <img src={blog.image} alt={blog.header} className={styles.cardImage} />
                            <div className={styles.cardContent}>
                                <h4 className={styles.cardHeader}>{blog.header}</h4>
                                <p className={styles.cardText}>
                                    {truncateContent(blog.content)}
                                    <span className={styles.viewMore}> Read More</span>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Blog;
