import React, { Fragment } from 'react';
import ProductCard from './ProductCard';
import styles from './ProductCarousel.module.css';

const ProductCarousel = ({ categoryHeading, events }) => {
    
    return (
        <Fragment>
            <div className={styles['product-carousel-container']}>
               <h1 className={styles['header-title']}>{categoryHeading}</h1> 
               <div className={styles['product-list-container']}>
                   
                    {events.map((event) => (
                        <div key={event.id} className={styles['product-card-container']}>
                           <ProductCard product={event} />
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default ProductCarousel;
