import React from 'react';
import {useParams} from 'react-router-dom';
import inventoryData from '../../data/inventoryData';
import ProductCarousel from '../../components/ProductCarousel';
const Catalogue = () => {
const {productCategory} = useParams();

    const eventType= 'wedding';
    const filteredCategoryData = inventoryData.filter((item)=> item.category === productCategory && item.eventType === eventType);

    const sortedCategoryData = filteredCategoryData.sort((a, b) => 
        a.title.localeCompare(b.title)
    );

    const categoryHeading = sortedCategoryData.length > 0 ? sortedCategoryData[0].category : 'No Category Found';
    return (
        <div>
<ProductCarousel categoryHeading={categoryHeading} events={sortedCategoryData} />
        </div>
    );
};

export default Catalogue;

