
import styles from './Btn.module.css';
const AddToCartBtn = ({ onClick,title }) => {
    return (
        <button onClick={onClick} className={styles.btn}>
            {title}
        </button>
    );
};

export default AddToCartBtn;



