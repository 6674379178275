import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BackBtn from './BackBtn';
import Button from './AddToCartBtn';
import inventoryData from '../data/inventoryData';
import styles from './Product.module.css';
import { Helmet } from 'react-helmet';
import RelatedItems from '../components/RelatedItems/RelatedItems';   

const Product = ({ addToCart }) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const navigate = useNavigate();
    const [isCustomerRequestModalOpen, setIsCustomerRequestModalOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const { productId, productTitle } = useParams();
    const product = inventoryData.find(item => item.id === productId);

    const currentData = inventoryData.find(
        data => data.id === productId && data.title === productTitle
    );

    const [imageSelector, setImageSelector] = useState('');

    const imageSelectorHandler = (e, param) => {
        setImageSelector(param);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const formatCost = (cost) => {
        const options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        return new Intl.NumberFormat('en-US', options).format(cost);
    };

    const addToCartHandler = () => {
        const newItem = {
            id: currentData.id,
            img: currentData.img1,
            title: currentData.title,
            quantity: quantity,
            price: currentData.cost,
            desc: currentData.desc,
            event: currentData.eventType
        };

        addToCart(newItem);

        setIsCustomerRequestModalOpen(true);
    };

    const handleGoToCart = useCallback(() => {
        setIsCustomerRequestModalOpen(false);
        navigate('/cart');
    }, [navigate]);

    const handleContinueShopping = () => {
        setIsCustomerRequestModalOpen(false);
    };

    if (!currentData) {
        return <p>Product not found</p>;
    }
    if (!product) {
        return <p>Product not found!</p>;
    }

    return (
        <Fragment>
            <Helmet>
                <title>{currentData.title} - Personalized Cards and Invitations</title>
                <meta name="description" content={currentData.desc} />
            </Helmet>

            <main className={styles.container}>
                <div className={styles['left-column']}>
                    {isDesktop && <BackBtn />}
                    <img src={imageSelector || currentData.img1} alt="product" loading="lazy" />
                </div>
                <div className={styles['right-column']}>
                    <div className={styles['product-description']}>
                        <span>{currentData.category} / {currentData.title} / {currentData.id}</span>
                        <h1>{currentData.title}</h1>
                        <p>{currentData.desc}</p>
                    </div>

                    <div className={styles['product-configuration']}>
                        <div className={styles['product-color']}>
                            <p>Images</p>
                            <div className={styles['product-img-choose']}>
                                <img className={styles['product-mini-img']} src={currentData.img1} alt="img1" onClick={(e) => imageSelectorHandler(e, currentData.img1)} loading="lazy" />
                                <img className={styles['product-mini-img']} src={currentData.img2} alt="img2" onClick={(e) => imageSelectorHandler(e, currentData.img2)} loading="lazy" />
                            </div>
                        </div>
                    </div>

                    <div className={styles['product-quantity']}>
                        <label htmlFor="quantity">Quantity: </label>
                        <select id="quantity" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))}>
                            {[...Array(100).keys()].map(i => (
                                <option key={i} value={i + 1}>{i + 1}</option>
                            ))}
                        </select>
                    </div>

                    <div className={styles['product-price']}>
                        <p>{formatCost(currentData.cost)}</p>
                        <Button title="Add to Cart" onClick={addToCartHandler} />
                    </div>
                </div>
            </main>

            {isCustomerRequestModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <p>Item added to cart! Would you like to go to the cart or continue shopping?</p>
                        <div className={styles.modalButtons}>
                            <button onClick={handleGoToCart}>Go to Cart</button>
                            <button onClick={handleContinueShopping}>Continue Shopping</button>
                        </div>
                    </div>
                </div>
            )}

            <RelatedItems inventoryData={inventoryData} currentProduct={product} />
        </Fragment>
    );
};

export default Product;
